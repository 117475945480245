import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthStore, useDraftCaseStore, useProgressBarNeuroStore } from '../../../../../../store/hooks';
import { useDraftCaseApi } from '../../../../../../apiHooks/useDraftCaseNeuroApi';
import { convertFirstStageToRequest } from '../../../../../../utils/convertStageObjectsNeuro';
import { generateLink, UserLink } from '../../../../../../utils';
import { RoleCategory } from '../../../../../../constants/projectUsers';
import { IDraftLayout } from './DraftLayout.helper';
import { AddCaseActionStatus } from '../../../../../../constants/addCaseStatus';
import { useModal } from '../../../../../shared/modal/useModal.vm';
import { ModalType } from '../../../../../../constants/modalType';

export const useDraftLayout = ({ validNext, stage }: IDraftLayout) => {
  const progressBarStore = useProgressBarNeuroStore();
  const draftCaseStore = useDraftCaseStore();
  const { areas } = useAuthStore();
  const { draftCaseNeuro, abbreviationNeuro } = draftCaseStore;
  const { firstStage, secondStage, fourthStage, isLoaded, currentStage } = progressBarStore;
  const [initialConvert, setInitialConvert] = useState(false);
  const { id } = useParams();
  const { isModalOpened, closeModal, handleModal } = useModal();

  const navigate = useNavigate();
  const {
    postDraftCase,
    getDraftCase,
    putFirstStageDraftCase,
    putSecondStageDraftCase,
    putFourthStageDraftCase
  } = useDraftCaseApi();

  const { stagesSequence, component, name } = stage;
  const [prevArrow, nextArrow] = stagesSequence;

  const areaStatus = areas && areas?.find(el => el.shortName === abbreviationNeuro)?.addCaseActionStatus;

  const showComment = () => {
    handleModal(ModalType.VIEW_MODAL);
  };

  useEffect(() => {
    if (areaStatus === AddCaseActionStatus.BLOCKED && !id) {
      navigate(generateLink(RoleCategory.USER, UserLink.HOME));
    }
  }, [areaStatus, id, navigate]);

  useEffect(() => {
    if (id) {
      void getDraftCase(+id);
      setInitialConvert(true);
    }
  }, [getDraftCase, id]);

  useEffect(() => {
    if (draftCaseNeuro) {
      progressBarStore.convert(draftCaseNeuro, initialConvert);
      setInitialConvert(false);
    }
  }, [draftCaseNeuro, initialConvert, progressBarStore]);

  useEffect(() => {
    return () => {
      draftCaseStore.resetDraftCaseNeuro();
    };
  }, [draftCaseStore]);

  const prevArrowHandler = async () => {
    if (prevArrow.stage) {
      progressBarStore.setCurrentStage(prevArrow.stage);
    }
  };

  const nextArrowHandler = async () => {
    if (validNext && nextArrow.stage) {

      if (nextArrow.stage === 2 && firstStage) {
        progressBarStore.setClicked(false);
        if (!id) {
          const idParam = await postDraftCase(convertFirstStageToRequest(firstStage));
          if (idParam) {
            navigate(generateLink(RoleCategory.USER, UserLink.EDIT_CASE_NEURO, idParam));
          }
        } else {
          const res = await putFirstStageDraftCase(+id, convertFirstStageToRequest(firstStage));
          if (res) {
            progressBarStore.setCurrentStage(nextArrow.stage);
            return;
          }
        }
      } else if (nextArrow.stage === 3 && secondStage && id) {
        const res = await putSecondStageDraftCase(+id, secondStage);
        if (res) {
          await getDraftCase(+id);
          progressBarStore.setCurrentStage(nextArrow.stage);
          return;
        }
      } else if (nextArrow.stage === 4 && id) {
        await getDraftCase(+id);
      } else if (nextArrow.stage === 5 && fourthStage && id) {
        const res = await putFourthStageDraftCase(+id, fourthStage);
        if (res) {
          await getDraftCase(+id);
          progressBarStore.setCurrentStage(nextArrow.stage);
          return;
        }
      }
      progressBarStore.setCurrentStage(nextArrow.stage);
    } else {
      progressBarStore.setClicked(true);
    }
  };

  const isShow = !!id ? isLoaded : true;
  const isBottomArrowHiddenMobile = draftCaseNeuro && ((currentStage === 3 && draftCaseNeuro?.drugs.length === 0) || currentStage >= 5);
  const tooltipNext = (draftCaseNeuro && currentStage === 3) ? 'Proszę utworzyć lek lub uzupełnić i zapisać dane dla każdego leku' : 'Proszę uzupełnić danę przez przejściem na kolejny krok';
  return {
    isBottomArrowHiddenMobile,
    isShow,
    prevArrow,
    prevArrowHandler,
    nextArrow,
    nextArrowHandler,
    component,
    name,
    tooltipNext,
    isModalOpened,
    closeModal,
    showComment,
    comment: draftCaseNeuro?.comment
  };
};