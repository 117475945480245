import {
  CloseContainer,
  Dropdownmenu,
  Logout,
  StyledContainer,
  StyledMenu,
  StyledNavlink,
  StyledNavlinkFooter,
  Text
} from './NavigationMenu.styled';
import { ReactComponent as Menu } from '../../../../../assets/icon/menu.svg';
import { ReactComponent as Close } from '../../../../../assets/icon/closeMenu.svg';
import { ReactComponent as LogoutIcon } from '../../../../../assets/icon/logout.svg';
import React, { useState } from 'react';
import logo from '../../../../../assets/img/logo.png';
import { NavLink, useNavigate } from 'react-router-dom';
import { RoleCategory, RoleFunction } from '../../../../../constants/projectUsers';
import { GeneralLink, generateLink, UserLink } from '../../../../../utils';
import { useModal } from '../../../modal/useModal.vm';
import { SideEffect } from '../../footer/sideEffects/SideEffects';
import { useAuthStore } from '../../../../../store/hooks';
import { useAuthApi } from '../../../../../apiHooks';
import { observer } from 'mobx-react-lite';

export const NavigationMenu = observer(() => {
  const { roleFunction } = useAuthStore();
  const { serverLogoutUser } = useAuthApi();
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();
  const { handleModal, isModalOpened, closeModal } = useModal();

  const isPublishing = roleFunction === RoleFunction.ROLE_PUBLISHING;
  const isGuest = roleFunction === RoleFunction.ROLE_GUEST;

  const logoutHandler = async () => {
    const isLogout = await serverLogoutUser();
    if (isLogout) {
      navigate(generateLink(RoleCategory.GUEST, GeneralLink.LOGIN));
      setOpen(false);
    }
  };
  return (
    <>
      <StyledMenu onClick={() => setOpen(true)}>
        <Menu />
      </StyledMenu>
      {isOpen && <Dropdownmenu>
        {!isGuest && <StyledContainer>
          <CloseContainer><Close onClick={() => setOpen(false)} /></CloseContainer>
          <img src={logo} alt='' width={120} />
          <div>
            {isPublishing && <StyledNavlink><NavLink to={generateLink(RoleCategory.USER, UserLink.HOME)}>Strona
              główna</NavLink></StyledNavlink>}
            {isPublishing &&
              <StyledNavlink><NavLink to={generateLink(RoleCategory.USER, UserLink.ADD_CASE_NEURO)}>Dodaj opis
                przypadku</NavLink></StyledNavlink>}
            {isPublishing &&
              <StyledNavlink><NavLink to={generateLink(RoleCategory.USER, UserLink.MY_CASES)}>Moje opisy
                przypadków</NavLink></StyledNavlink>}
            <StyledNavlink><NavLink to={generateLink(RoleCategory.USER, UserLink.OTHER_CASES)}>Opisy przypadków
              innych</NavLink></StyledNavlink>
            {isPublishing && <StyledNavlink><NavLink to={generateLink(RoleCategory.USER, UserLink.MY_RECKONING)}>Moje
              rozliczenia</NavLink></StyledNavlink>}
            <StyledNavlink><NavLink to={generateLink(RoleCategory.USER, UserLink.PROFILE)}>Mój
              profil</NavLink></StyledNavlink>
          </div>
        </StyledContainer>}

        <StyledContainer>
          {isGuest && <>
            <CloseContainer><Close onClick={() => setOpen(false)} /></CloseContainer>
            <img src={logo} alt='' width={120} />
          </>}
          <div>
            <StyledNavlinkFooter><a href='/' target='_blank' rel='noreferrer'>Kontakt</a></StyledNavlinkFooter>
            <StyledNavlinkFooter><a href='/' target='_blank' rel='noreferrer'>Polityka
              Prywatności</a></StyledNavlinkFooter>
            <StyledNavlinkFooter><a href='/' target='_blank' rel='noreferrer'>Regulamin
              serwisu</a></StyledNavlinkFooter>
          </div>
          <Text isButton={true} onClick={() => handleModal()}>
            zgłoś działanie niepożądane
          </Text>

        </StyledContainer>
        {!isGuest && <Logout
          onClick={() => logoutHandler()}>Wyloguj <LogoutIcon />
        </Logout>}
        {isModalOpened && <SideEffect closeModal={closeModal} />}
      </Dropdownmenu>}
    </>
  );
});