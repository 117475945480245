import theme from '../../../../../theme/theme';
import { Button } from '../../../button/Button';
import React from 'react';

import { NavigationContainer } from './NavigationButton.styled';
import { useLocation } from 'react-router-dom';
import { generateLink, UserLink } from '../../../../../utils';
import { RoleCategory } from '../../../../../constants/projectUsers';
import { Modal } from '../../../modal/Modal';
import { ContentModal } from '../../../modal/ContentModal';
import {
  ConfirmationTextModal, FlexPosition,
  Paragraph,
  Title
} from '../../../modal/sharedModals/confirmationModal/ConfirmationModal.styled';
import { Select } from '../../../form/select/Select';
import { useNavigateButtons } from './useNavigateButtons';
import { ModalType } from '../../../../../constants/modalType';

export const NavigationButtons = () => {
  const location = useLocation();
  const {
    addCase,
    isModalOpened,
    closeModal,
    selectOptions,
    setSelectedValue,
    addCaseFurther,
    selectValue,
    isError,
    modalType
  } = useNavigateButtons();

  const listOfButtons = [{
    text: 'dodaj opis przypadku',
    link: '',
    onClick: addCase
  }, {
    text: 'moje opisy przypadków',
    link: generateLink(RoleCategory.USER, UserLink.MY_CASES)
  }, {
    text: 'inne opisy przypadków',
    link: generateLink(RoleCategory.USER, UserLink.OTHER_CASES)
  }, {
    text: 'moje rozliczenia',
    link: generateLink(RoleCategory.USER, UserLink.MY_RECKONING)
  }];

  return (
    <NavigationContainer>
      {listOfButtons.map(({ text, link, onClick }) => (
        <Button text={text} link={link}
                clickHandler={onClick ? onClick : undefined}
                key={text}
                width={'100%'}
                size={'small'}
                color={theme.colors.beige}
                backgroundColor={location.pathname === link ? theme.colors.turquoise2 : theme.colors.darkBlue}
                fontSize={'12px'}
        />))}
      {isModalOpened &&
        <Modal clickHandler={() => closeModal()}>
          {modalType === ModalType.SELECT_AREA  && <ContentModal
            text={<ConfirmationTextModal>
              <Title>Wybierz obszar terapeutyczny</Title>
              <Paragraph>Aby utworzyć nowy opis przypadku medycznego należy wybrać obszar terapeutyczny,
                który go
                dotyczy.</Paragraph>
              <FlexPosition>
                {selectOptions &&
                  <Select
                    width={'245px'}
                    defaultValue='Obszar terapeutyczny'
                    selectValue={selectValue}
                    elementName={'area-filter'}
                    setValue={(value: string | number) => setSelectedValue(+value)}
                    optionsArray={selectOptions}
                    errorMessage={'Został osiągnięty limit opisów przypadków w tym obszarze terapeutycznym.'}
                    isError={isError} />}
                <Button
                  text={'Dalej'}
                  disabled={selectValue === -1}
                  width={'189px'}
                  size={'small'}
                  color={theme.colors.beige}
                  backgroundColor={theme.colors.dark1}
                  fontSize={'16px'}
                  clickHandler={addCaseFurther}
                /></FlexPosition>
            </ConfirmationTextModal>}
          />}
          {modalType === ModalType.MODAL_BLOCKED &&
          <ContentModal
            text={<ConfirmationTextModal>
              Wprowadzanie opisów przypadków zostało wyłączone. Prosimy pamiętać o dokonaniu rozliczeń.<br/>Serdecznie dziękujemy za udział w projekcie.
              
              </ConfirmationTextModal>}
          />
          }
        </Modal>
        }
    </NavigationContainer>
  );
};


