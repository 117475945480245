export enum ModalType {
  VIEW_MODAL,
  ADD_MODAL,
  DELETE_MODAL,
  DELETE_CONFIRMATION_MODAL,
  DELETE_ERROR_MODAL,
  EDIT_MODAL,
  EDIT_CONFIRMATION_MODAL,
  ADD_DRUG,
  ADD_INCIDENT,
  EDIT_INCIDENT,
  ACCEPT_CASE_MODAL,
  ACCEPT_CASE_CONFIRMATION_MODAL,
  REJECT_CASE_MODAL,
  REJECT_CASE_CONFIRMATION_MODAL,
  READ_COMMENT,
  LIGHTBOX,
  MY_RECKONING_NOT_ACCEPTED_MODAL,
  MY_RECKONING_SELECTION_MODAL,
  MY_RECKONING_DOCUMENT_PLACEMENT_MODAL,
  MY_RECKONING_CONFIRMATION_MODAL,
  ACCEPT,
  PAY,
  GENERATE_REPORT,
  SELECT_AREA,
  MODAL_BLOCKED
}