import { useHomeApi } from '../../../../../apiHooks';
import { useEffect } from 'react';
import {
  useAuthStore,
  useHomeStore
} from '../../../../../store/hooks';
import useBreakpoint from '../../../../../hooks/useBreakpoint';

export const usePublishUserHome = () => {
  const { getListOfLastCases } = useHomeApi();
  const homeStore = useHomeStore();
  const { listOfLastCases, isListOfLastCasesLoaded } = homeStore;
  const breakpoint = useBreakpoint();
  const authStore = useAuthStore();
  const { fullName, affiliation, areas } = authStore;

  useEffect(() => {
    if (!isListOfLastCasesLoaded) {
      if (listOfLastCases === null) {
        void getListOfLastCases();
      } else {
        void getListOfLastCases(false);
      }
    }
  }, [listOfLastCases, getListOfLastCases, isListOfLastCasesLoaded]);

  useEffect(() => {
    return () => {
      homeStore.resetListOfLastCases();
    };
  }, [homeStore]);

  const isNeuroAffiliation = areas?.length === 1 && areas[0].fullName === 'Neurologia';

  return {
    listOfLastCases,
    fullName,
    affiliation,
    breakpoint,
    isNeuroAffiliation
  };
};